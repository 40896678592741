import { isEmpty } from 'lodash';
import 'moment-timezone';
import { Box, Text } from '@chakra-ui/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as LocalStore from 'store';
import { v4 as uuidv4 } from 'uuid';

import Loader from '../components/Loader';
import { useSession } from '../hooks/session';
import { useLazyGetUserQuery } from '../redux/api/accountApi';
import { useGetSessionQuery } from '../redux/api/authApi';
import {
  useAddToCartMutation,
  useLazyGetCartQuery,
  useUpdateCartMutation,
} from '../redux/api/cartApi';
import { emptySplitApi } from '../redux/api/emptySplitApi';
import {
  setAccessToken,
  setProfileRole,
  setSelectedEnterprise,
  setUser,
} from '../redux/slices/authSlice';
import {
  ACCOUNT_TYPE_VALUES,
  APPROVED_EXPERT,
  PRODUCT_SUBSCRIPTION_VALUES,
} from '../utils';
import { connectToFrontlineSocket, connectToSocket } from '../utils/webSockets';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [socket, setSocket] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const [authError, setAuthError] = useState(null);

  const {
    data: sessionData,
    isLoading,
    isFetching,
    isError,
    error: sessionError,
    refetch: refetchSession,
  } = useGetSessionQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    skip: true,
  });

  const [getUser, { isFetching: isUserFetching }] = useLazyGetUserQuery();
  const authState = useSelector(store => store?.auth);
  const isAuthenticated =
    !isEmpty(authState?.accessToken) ||
    (!isError &&
      !isFetching &&
      !isLoading &&
      !isEmpty(sessionData?.access_token));
  const isEnterprise = (authState?.user?.type || []).includes(
    ACCOUNT_TYPE_VALUES.ENTERPRISE
  );
  const isUnlimitedEnterprise = (authState?.user?.type || []).includes(
    ACCOUNT_TYPE_VALUES.UNLIMITED_ENTERPRISE
  );
  const isSysAdmin = (authState?.user?.type || [])?.includes(
    ACCOUNT_TYPE_VALUES.SYS_ADMIN
  );
  const [fetchCart, { data: cartData, isFetching: isCartFetching }] =
    useLazyGetCartQuery();
  const [updateCartItem, { isLoading: isCartUpdating }] =
    useUpdateCartMutation();
  const [
    addCartItem,
    { isLoading: isCartItemAdding, originalArgs: addCartItemArgs },
  ] = useAddToCartMutation();

  const handleLogin = () => {};

  const handleLogout = useCallback(() => {
    dispatch(setAccessToken(null));
    dispatch(setUser(null));
    dispatch(setSelectedEnterprise(null));
    localStorage.removeItem('authState');
    dispatch(emptySplitApi.util.resetApiState());
    navigate('/login', { replace: true });
  }, [dispatch, navigate]);

  const getEnterpriseId = () => {
    try {
      if (authState?.selectedEnterprise?._id) {
        const enterpriseId = authState?.selectedEnterprise._id;
        return enterpriseId?.toString();
      }

      const isEnterpriseSub = [
        PRODUCT_SUBSCRIPTION_VALUES.ENTERPRISE,
        PRODUCT_SUBSCRIPTION_VALUES.UNLIMITED_ENTERPRISE,
      ].includes(authState?.user?.subscription?.product?.name);

      if (isEnterpriseSub && authState?.user?._id) {
        const enterpriseId = authState?.user._id;
        return enterpriseId?.toString();
      }
      const enterpriseId = authState?.user?.parent?._id || authState?.user?._id;
      return enterpriseId?.toString() || null;
    } catch (error) {
      console.error('Error getting enterpriseId:', error);
      return null;
    }
  };
  
  useEffect(() => {
    if (sessionError) {
      dispatch(setAccessToken(null));
      dispatch(setUser(null));
      dispatch(emptySplitApi.util.resetApiState());
    }
  }, [sessionError]);

  const handleUpdateCart = useCallback(
    async payload => {
      if (payload?._id) {
        await updateCartItem({
          cartId: cartData?._id,
          id: payload?._id,
          body: payload,
        });
      } else {
        await addCartItem({ cartId: cartData?._id, body: payload });
      }
    },
    [updateCartItem, addCartItem, cartData]
  );

  useEffect(() => {
    if (sessionData?.access_token) {
      dispatch(setAccessToken(sessionData?.access_token));
      dispatch(setUser(sessionData?.data));
      dispatch(setProfileRole(sessionData?.data?.type?.[0]));
    }
  }, [sessionData, dispatch]);

  useEffect(() => {
    if (sessionData?.access_token || authState?.accessToken) {
      connectToSocket(
        sessionData?.access_token || authState?.accessToken,
        dispatch,
        setSocket,
        location.pathname
      );
    }
  }, [sessionData?.access_token, authState?.accessToken]);

  useEffect(() => {
    const initAuth = async () => {
      try {
        const userObject = await getUser();
        const user = userObject.data;
        
        if (user) {
          dispatch(setUser(user));
          
          const enterpriseData = user.parent ? {
            value: user.parent._id,
            label: `cname: ${user.parent.company_name} name: ${user.parent.name} userId: ${user.parent.ubimaxId} (default)`,
            id: user.parent._id,
            _id: user.parent._id,
          } : {
            value: user._id,
            label: `cname: ${user.company_name} name: ${user.name} userId: ${user.ubimaxId} (default)`,
            id: user._id, 
            _id: user._id,
          };

          dispatch(setSelectedEnterprise(enterpriseData));
        }
        
        setIsInitialized(true);
      } catch (error) {
        console.error('Auth initialization error:', error);
        setAuthError(error);
      }
    };

    initAuth();
  }, []);

  useEffect(() => {
    const getCart = async () => {
      let session = LocalStore.get('cart_session');
      if (!session) {
        session = uuidv4();
        LocalStore.set('cart_session', session);
      }
      await fetchCart(session);
    };
    getCart();
  }, [fetchCart, isAuthenticated]);

  useEffect(() => {
    const onStorageUpdate = e => {
      const { key, value, url } = e;
      if (key === 'authState' && !url.includes('/login')) {
        if (!value) {
          handleLogout();
        }
      }
    };
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, [handleLogout]);

  const createNewCartSession = async () => {
    LocalStore.remove('cart_session');
    const session = uuidv4();
    LocalStore.set('cart_session', session);
    await fetchCart(session);
  };

  const getLocalProfileRole = () => {
    const role = LocalStore.get('_ptex');
    return role || 'tech';
  };

  const onProfileRoleChange = useCallback(
    role => {
      dispatch(setProfileRole(role));
    },
    [dispatch]
  );

  if (!isInitialized && !authError) {
    return <Loader />;
  }

  if (authError) {
    return (
      <Box p={4} textAlign="center">
        <Text color="red.500">Error initializing authentication</Text>
      </Box>
    );
  }

  const enterpriseId = getEnterpriseId();
if (!/^[a-fA-F0-9]{24}$/.test(enterpriseId)) {
  console.error('Invalid Enterprise ID format:', enterpriseId);
}
  
  if (location.pathname.includes('/enterprise-library') && !enterpriseId) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        isEnterprise,
        isUnlimitedEnterprise,
        isSysAdmin,
        isPaidUser: Boolean(authState?.user?.parent?.name),
        isUnlimitedPaidUser: Boolean(
          authState?.user?.type?.includes(ACCOUNT_TYPE_VALUES.UNLIMITED_ENTERPRISE) && 
          authState?.user?.unlimitedPaid
        ),
        isEnterprisePaidUser: Boolean(
          authState?.user?.subscription?.product?.name === 
          PRODUCT_SUBSCRIPTION_VALUES.ENTERPRISE
        ),
        cartState: cartData,
        isAuthenticated,
        isCartLoading: isCartFetching || isCartUpdating || isCartItemAdding,
        addCartItemArgs,
        isSessionLoading: isFetching || isUserFetching,
        handleLogin,
        handleLogout,
        handleUpdateCart,
        createNewCartSession,
        enterpriseId,
        onProfileRoleChange,
        socket,
        setSocket,
      }}
    >
      {(isLoading || isFetching) && <Loader />}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;